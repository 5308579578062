<template>
  <div>
    <b-form>
      <label>数据映射（percent）</label>
      <b-form-group>
        <b-form-input v-model="option.percent" />
      </b-form-group>

      <label>进度条的宽度与总宽度之比（barWidthRatio）</label>
      <b-form-group>
        <b-form-input v-model="option.barWidthRatio" />
      </b-form-group>

      <label>进度条已完成部分颜色（shape）</label>
      <b-form-group>
        <v-select
          v-model="option.color"
          :class="'border-left-' + option.color"
          :clearable="false"
          :options="color_list"
          :reduce="(text) => text.value"
          class="rounded"
          label="text"
        >
          <template #option="{ text, value }">
            <span :class="'text-' + value"> {{ text }}</span>
          </template>
        </v-select>
      </b-form-group>

      <!-- <label>进度条未完成部分颜色（shape）</label>
      <b-form-group>
        <v-select
          v-model="option.shape"
          :clearable="false"
          :options="params_shape_list"
          :reduce="(text) => text.value"
          class="rounded"
          label="text"
        ></v-select>
      </b-form-group> -->
    </b-form>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { reactive, toRefs, computed } from "@vue/composition-api";
import store from "@/store";
import vSelect from "vue-select";

export default {
  name: "G2ProgressStickSettings",
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: ["item"],
  setup(props) {
    const { item } = props;
    const event = reactive({
      option: {
        percent: "0.1",
        barWidthRatio: "0.5",
        color: "success",
      },
      color_list: computed(() => {
        let list = [];
        Object.keys(store.state.appConfig.colors).map((color) => {
          list.push({
            text: color,
            value: color,
          });
        });
        return list;
      }),
    });
    // watch(() => event.selectedFile, (file) => {
    // 	if (!!file) {
    // 		uploadFile(file)
    // 	}
    // })
    //更新store的数据
    // const update_store_options=()=>{
    // 	let options = store.state['panel-group']['panel-modal']['options']
    // 	options.list = event.list
    // 	store.commit('panel-group/UPDATE_PANEL_MODAL_OPTIONS', { ...options })
    // }
    return {
      ...toRefs(event),
    };
  },
};
</script>
