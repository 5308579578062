<template>
  <div class="w-100 h-100 position-relative">
    <!-- <h5 class="text-center text-danger" style="margin-top: 10%">进度图-条形</h5> -->
    <div id="stickView"></div>
  </div>
</template>

<script>
import { Progress } from "@antv/g2plot";
import { onMounted } from "@vue/composition-api";
export default {
  name: "G2ProgressStickView",
  setup() {
    const initProgress = () => {
      const progress = new Progress("stickView", {
        height: 100,
        width: 300,
        autoFit: false,
        percent: 0.536,
        barWidthRatio: 0.3,
        color: ["#F4664A", "#E8EDF3"],
      });

      progress.render();
    };

	onMounted(() => {
		console.log("进度条组件")
		initProgress();
    });

  },
};
</script>
