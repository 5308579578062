export default [
    {
        path: "/:project_id/cabr/diagnosis/dashboard",
        name: "project-cabr-dashboard",
        component: () => import("@/views/front/cabr/diagnosis/dashboard"),
    },
    {
        path: "/:project_id/cabr/diagnosis/dashboard-around",
        name: "project-cabr-dashboard",
        component: () => import("@/views/front/cabr/diagnosis/dashboard/index-around"),
    },
    {
        path: "/:project_id/cabr/diagnosis/risk",
        name: "index-diagnosis-risk",
        component: () => import("@/views/front/cabr/diagnosis/calendar/index.vue"),
    },
    {
        path: "/:project_id/cabr/diagnosis/dashboard_back",
        name: "project-cabr-dashboard",
        component: () => import("@/views/front/cabr/diagnosis/dashboard/index.back.vue"),
    },
    //微服务列表页面
    {
        path: "/:project_id/cabr/diagnosis/agent-list",
        name: "index-agent-list",
        component: () => import("@/views/front/cabr/diagnosis/agent-list/AgentList.vue"),
    },
    //故障详情页面
    {
        path: "/:project_id/cabr/diagnosis/agent-detail",
        name: "index-agent-detail",
        component: () => import("@/views/front/cabr/diagnosis/process/AgentDetail2.vue"),
    },
    //故障分析页面
    {
        path: "/:project_id/cabr/diagnosis/agent-analyse",
        name: "index-agent-analyse",
        component: () => import("@/views/front/cabr/diagnosis/agent-analyse/AgentAnalyse.vue"),
    },
    // 规则图谱
    {
        path: "/:project_id/cabr/diagnosis/relation-graph",
        name: "FunMenu_relationGraph",
        component: () => import("@/views/front/cabr/diagnosis/relation-graph/relationGraph.vue"),
    },
]
