import Vue from "vue";
import Toast from "vue-toastification";

// Toast Notification Component Styles
import "@/@core/scss/vue/libs/toastification.scss";

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, {
	hideProgressBar: true,
	closeOnClick: true,
	// 失去焦点时自动暂停
	pauseOnFocusLoss: false,
	// 悬停时自动暂停
	pauseOnHover: true,
	showCloseButtonOnHover: true,
	icon: false,
	timeout: 5000,
	newestOnTop: true,
	maxToasts: 5,
	position: "bottom-right",
	transition: "Vue-Toastification__fade",
});
