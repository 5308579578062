import store from "@/store";
import router from "@/router";

export function routerParams(paramKey = "project_id") {
	const currentRoute = router.currentRoute;
	const param_route = currentRoute.params[paramKey];
	const param_store = store.state.projects.selected[paramKey];
	const param_localStorage = localStorage.getItem(paramKey);
	return param_route || param_store || param_localStorage;
}
