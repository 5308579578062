import {addUserInfo, assignedProject, changeUserPassword, changeUserRole, deleteUser, detachProject, getProjectList, getRoleList, getUserDetail, getUserList, updateUserBasicInfo} from "@/api/users";

export default {
	namespaced: true,
	state: {
		userData: {
			projects: [],
		},
	},
	getters: {},
	mutations: {
		UPDATE_USER_USERDATA(state, val) {
			state["userData"] = val;
		},
		UPDATE_USER_USERDATA_PROJECTS(state, val) {
			state["userData"]["projects"] = val;
		},
	},
	actions: {
		fetchUsers(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getUserList({ params: queryParams })
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		fetchUser(ctx, id) {
			return new Promise((resolve, reject) => {
				getUserDetail(id)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		editUser(ctx, data) {
			return new Promise((resolve, reject) => {
				updateUserBasicInfo(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		addUser(ctx, data) {
			return new Promise((resolve, reject) => {
				addUserInfo(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		assignProject(ctx, data) {
			return new Promise((resolve, reject) => {
				assignedProject(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		detachProject(ctx, data) {
			return new Promise((resolve, reject) => {
				detachProject(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		deleteUserInfo(ctx, id) {
			return new Promise((resolve, reject) => {
				deleteUser(id)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		getRole(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getRoleList({ params: queryParams })
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		changeRole(ctx, data) {
			return new Promise((resolve, reject) => {
				changeUserRole(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		changePassword(ctx, data) {
			return new Promise((resolve, reject) => {
				changeUserPassword(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		fetchProjects(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getProjectList({ params: queryParams })
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
	},
};
