import store from "@/store";
import { showToast } from "@/libs/utils/showToast";
import { routerParams } from "@/libs/utils/routerParams";

export async function refreshMenu(project_id) {
	const project_selected = project_id || routerParams("project_id");
	if (!!project_selected) {
		store
			.dispatch("menus/fetchMenusTree", {
				project_id: project_selected,
				sortBy: "order",
			})
			.then((response) => {
				const menus_storage = response.data;
				localStorage.setItem("menus", JSON.stringify(menus_storage));
				store.commit("menus/UPDATE_MENU_TREE", menus_storage);
			})
			.catch((error) => {
				showToast(error);
			});
	}
}
