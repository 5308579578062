<template>
	<div class="position-relative">
    <b-form-file v-model="selectedFile" accept="image/*" class="mt-1 btn btn-primary position-absolute rounded" plain style="bottom: 0" />
    <b-badge v-if="percent > 0 && percent < 100" class="position-absolute" style="bottom: 0.7rem; right: 1rem; width: 4rem" variant="success">{{ percent }}% </b-badge>
		<b-table :fields="fields" :items="list" responsive small>
			<!-- A custom formatted column -->
			<template #cell(name)="data">
				{{ data.item.index }}.
				<b-button v-ripple.400="'rgba(255,255,255, 0.15)'" class="btn-icon rounded-circle" size="sm" style="padding: 0.2rem" variant="flat-danger" @click="deleteFile(data.index)">
					<feather-icon icon="XIcon" />
				</b-button>

				{{ data.value }}（{{ data.item.size }} kb）
			</template>
		</b-table>


	</div>
</template>

<script>
	import { reactive, toRefs, watch } from "@vue/composition-api";
	import Ripple from "vue-ripple-directive";
	import { cos_delete, cos_upload } from "@/libs/utils/uploadFiles";
	import { showToast } from "@/libs/utils/showToast";
	import store from "@/store";
	import { showConfirm } from "@/libs/utils/showConfirm";

	export default {
		name: "SwiperSettings",
		directives: {
			Ripple,
		},
		props: ["item"],
		setup(props) {
			const { item } = props;
			const event = reactive({
				selectedFile: null,
				percent: 0,
				fields: [
					{
						key: "name",
						label: "文件列表",
					},
				],
				list: item.options.list,
			});
			watch(
				() => event.selectedFile,
				(file) => {
					if (!!file) {
						uploadFile(file);
					}
				}
			);
			/**
			 * 上传文件
			 * @param file
			 */
			const uploadFile = (file) => {
				if (event.list.length < 5) {
					cos_upload(
						file,
						(progressData) => {
							event.percent = parseInt(progressData.percent * 100);
						},
						(result) => {
							//上传成功，push到列表中
							const fileObject = event.selectedFile;
							event.list.push({
								index: event.list.length + 1,
								name: result.filename,
								url: result.Location,
								filepath: result.filepath,
								size: Math.floor(fileObject.size / 1024),
								type: fileObject.type,
							});
							event.selectedFile = null;
							event.percent = 0;
							update_list();
						}
					);
				} else {
					showToast(null, "提示：", "上传文件数量上限为5 !");
				}
			};
			/**
			 * 删除文件
			 * @param index
			 */
			const deleteFile = (index) => {
				showConfirm("确定删除?", () => {
					const filepath = event.list[index].filepath;
					console.log(filepath);
					cos_delete(filepath, (result) => {
						console.log("deleted", result);
						event.list.splice(index, 1);
						showToast(null, "成功：", "文件删除成功 !");
						update_list();
					});
				});
			};
			//更新store的数据
			const update_list = () => {
				let options = store.state["panel-group"]["panel-modal"]["options"];
				options.list = event.list;
				store.commit("panel-group/UPDATE_PANEL_MODAL_OPTIONS", { ...options });
			};
			return {
				...toRefs(event),
				uploadFile,
				deleteFile,
			};
		},
	};
</script>
<style>
	.form-control-file {
		font-size: 0.8rem;
		padding: 0.8rem;
	}
</style>
