import useJwt from "@/libs/auth/jwt/useJwt";

const axios = useJwt.axiosIns;

export const getIndoorEnvironment = (project_id = 81) =>
    axios.get(`/api/xj/indoor_environment/${project_id}`);

export const getHours24Energy = (project_id = 81) =>
    axios.get(`/api/xj/params/hours-24-energy/${project_id}`);

export const getRealTimeEnergy = (params) =>
    axios.get(`/api/xj/params/real-time-energy/81`, {params});

export const getHotColdLoad = (params) =>
    axios.get(`/api/xj/params/hot_cold_load`, {params});
