export default [
	{
		path: "/errors/coming-soon",
		name: "error-coming-soon",
		component: () => import("@/views/errors/ComingSoon.vue"),
		meta: {
			layout: "full",
		},
	},
	{
		path: "/errors/error",
		name: "error",
		component: () => import("@/views/errors/Error.vue"),
		meta: {
			layout: "full",
		},
	},
	{
		path: "/errors/error404",
		name: "error-error404",
		component: () => import("@/views/errors/Error404.vue"),
		meta: {
			layout: "full",
		},
	},
	{
		path: "/errors/not-authorized",
		name: "error-not-authorized",
		component: () => import("@/views/errors/NotAuthorized.vue"),
		meta: {
			layout: "full",
			resource: "Auth",
		},
	},
	{
		path: "/errors/under-maintenance",
		name: "error-under-maintenance",
		component: () => import("@/views/errors/UnderMaintenance.vue"),
		meta: {
			layout: "full",
		},
	},
];
