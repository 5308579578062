import { addMenu, deleteMenu, getMenu, getMenus, getMenusTree, updateMenu } from "@/api/menus";

export default {
	namespaced: true,
	state: {
		tree: [],
		list: [],
		edit: {},
		current: {},
		// 页面内部导航
		inner_menu_current: {},
		// hover的菜单id
		hover: {
			lvl_1: null,
			lvl_2: null,
			lvl_3: null,
		},
	},
	getters: {},
	mutations: {
		// *===============================================---*
		// *--------- 菜单列表 ---------------------------------------*
		// *===============================================---*
		UPDATE_MENU_TREE(state, val) {
			state["tree"] = val;
		},
		UPDATE_MENU_CURRENT(state, val) {
			state["current"] = val;
		},
		UPDATE_MENU_EDIT(state, val) {
			state["edit"] = val;
		},
		UPDATE_MENU_INNER_MENU_CURRENT(state, val) {
			state["inner_menu_current"] = val;
		},
		UPDATE_MENU_HOVER_LVL_1(state, val) {
			state["hover"]["lvl_1"] = val;
		},
		UPDATE_MENU_HOVER_LVL_2(state, val) {
			state["hover"]["lvl_2"] = val;
		},
		UPDATE_MENU_HOVER_LVL_3(state, val) {
			state["hover"]["lvl_3"] = val;
		},
	},
	actions: {
		fetchMenus(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getMenus({ params: queryParams })
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		fetchMenusTree(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				getMenusTree({ params: queryParams })
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		fetchMenu(ctx, id) {
			return new Promise((resolve, reject) => {
				getMenu(id)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		editMenu(ctx, data) {
			return new Promise((resolve, reject) => {
				updateMenu(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		addMenu(ctx, data) {
			return new Promise((resolve, reject) => {
				addMenu(data)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		deleteMenu(ctx, id) {
			return new Promise((resolve, reject) => {
				deleteMenu(id)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
	},
};
