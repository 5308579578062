export default {
	namespaced: true,
	state: {
		click_item: null,
	},
	getters: {},
	mutations: {
		setClickItem(state, type) {
			state.click_item = type;
		},
	},
	actions: {},
};
