<template>
	<div @mouseenter="scroll_notice_stopscroll" @mouseleave="scroll_notice_autoscroll">
		<app-timeline>
			<app-timeline-item v-for="(listItem, listIndex) in query_data.temp_data" :key="listIndex" :variant="itemColor(listItem[option.field])" class="pb-1">
				<div v-html="itemFormat(listItem)"></div>
				<small class="timeline-item-time text-nowrap text-muted font-small-3">{{listItem['time']||'0000-00-00 00:00:00'}}</small>
			</app-timeline-item>
		</app-timeline>
	</div>
</template>

<script>
	import { computed, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import Ripple from "vue-ripple-directive";
	import store from "@/store";
	import AppTimeline from "@/@core/components/app-timeline/AppTimeline.vue";
	import AppTimelineItem from "@/@core/components/app-timeline/AppTimelineItem.vue";

	export default {
		components: {
			AppTimeline,
			AppTimelineItem,
		},
		directives: {
			Ripple,
		},
		props: ["item", "preview"],
		setup(props) {
			const { item, preview } = props;

			const event = reactive({
				panel_id: item.panel_id,
				option: computed(() => {
					return item.options;
				}),
				query_data: computed(() => {
					const query_key = "query_" + item.query_id;
					let query_data = store.state["panel-group"]["query-data-list"][query_key];
					if (!(query_data && query_data.temp_data)) {
						query_data = Object.assign({}, { temp_data: [] });
					}
					return query_data;
				}),
			});
			watch(
				() => event.option,
				() => {
					scroll_notice_autoscroll();
				}
			);
			/**
			 * 自动滚动
			 */
			const scroll_notice_autoscroll = () => {
				let parent = document.querySelector("#panel-" + item.panel_id + ".scroll-area");
				if (preview) {
					parent = document.querySelector("#panel-" + item.panel_id + "-preview.scroll-area");
				}
				let scroll_id = "scroll_" + item.panel_id;
				if (!window.scroll_notice) {
					window.scroll_notice = {};
				}
				if (window.scroll_notice[scroll_id]) {
					clearInterval(window.scroll_notice[scroll_id]);
				}
				window.scroll_notice[scroll_id] = setInterval(
					function () {
						if (parent.scrollTop >= parent.scrollHeight - parent.offsetHeight) {
							parent.scrollTop = 0;
						} else {
							parent.scrollTop++;
						}
					},
					event.option.speed ? parseInt(event.option.speed) : 100
				);
			};
			/**
			 * 停止自动滚动
			 */
			const scroll_notice_stopscroll = () => {
				let scroll_id = "scroll_" + item.panel_id;
				clearInterval(window.scroll_notice[scroll_id]);
			};

			const itemColor = (value) => {
				value = parseFloat(value);
				let color = "success";
				const range = event.option.range;
				if (value >= range.info_start && value <= range.info_end) {
					color = "success";
				} else if (value >= range.warning_end || value <= range.warning_start) {
					color = "danger";
				} else {
					color = "warning";
				}
				return color;
			};

			const itemFormat = (listItem) => {
				let str = "";
				for (let key in listItem) {
          if(key!=='time'){
            str += `<strong>${key}:</strong><span>${listItem[key]}</span><strong> ; </strong>`;
          }
				}
				return str;
			};

			onMounted(() => {
				scroll_notice_autoscroll();
			});
			return {
				...toRefs(event),
				scroll_notice_autoscroll,
				scroll_notice_stopscroll,
				itemColor,
				itemFormat,
			};
		},
	};
</script>
<style>
	.list-group-item {
		padding: 0.5rem;
	}
</style>
