export default [
    {
        path: "/phyMonitor",
        name: "FunMenu_PhyMonitor",
        component: () => import("@/views/front/common/agent-list/components/FunMenu/PhyMonitor"),
    },
    {
        path: "/AgentList",
        name: "FunMenu_AgentList",
        component: () => import("@/views/front/cabr/diagnosis/agent-list/AgentList.vue"),
    },
    {
        path: "/AgentResult",
        name: "AgentResult",
        component: () => import("@/views/front/common/agent-list/AgentResult"),
    },
    {
        path: "/AgentDetail/:id",
        name: "AgentDetail",
        component: () => import("@/views/front/common/agent-list/AgentDetail"),
    },
    {
        path: "/diagnosis/general",
        name: "DiagnosisGeneral",
        component: () => import("@/views/front/cabr/diagnosis/general/index.vue"),
    },
    {
        path: "/diagnosis/speciality",
        name: "DiagnosisSpeciality",
        component: () => import("@/views/front/cabr/diagnosis/speciality/index.vue"),
    },
    {
        path: "/AgentDetail2/:id",
        name: "AgentDetail2",
        component: () => import("@/views/front/cabr/diagnosis/process/AgentDetail2.vue"),
    },
    // {
    //     path: "/AgentDetail2/:id",
    //     name: "index-diagnosis-risk",
    //     component: () => import("@/views/front/common/agent-list/AgentDetail2"),
    // }
];
