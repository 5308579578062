import {getNotice} from "@/api/diagnosis";

export default {
    namespaced: true,
    state: {
        notices: []
    },
    mutations: {},
    actions: {
        fetchNotice({state}, payload) {
            getNotice().then(res => {
                state.notices = res.data
            })
        }
    }
}
