<template>
  <div class="w-100 h-100 position-relative">
    <h5 class="text-center text-danger" style="margin-top: 10%">进度图-子弹</h5>
	<div id="bulletView"></div>
  </div>
</template>

<script>
import {Bullet} from "@antv/g2plot"
export default {
  name: "G2ProgressBulletView",
};
</script>
