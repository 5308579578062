<template>
  <div>
    <b-form>
      <label>数据映射（percent）</label>
      <b-form-group>
        <b-form-input v-model="option.percent" />
      </b-form-group>

	  <label>外环半径（radius）</label>
      <b-form-group>
        <b-form-input v-model="option.radius" />
      </b-form-group>
	  
	  <label>内环半径（innerRadius）</label>
      <b-form-group>
        <b-form-input v-model="option.innerRadius" />
      </b-form-group>

	  <label>字体颜色</label>
	  <b-form-group>
        <v-select
          v-model="option.color"
          :class="'border-left-' + option.color"
          :clearable="false"
          :options="color_list"
          :reduce="(text) => text.value"
          class="rounded"
          label="text"
        >
          <template #option="{ text, value }">
            <span :class="'text-' + value"> {{ text }}</span>
          </template>
        </v-select>
      </b-form-group>

	  <label>字体大小</label>
	  <b-form-group>
        <b-form-input v-model="option.fontSize" />
      </b-form-group>

	  <label>行高</label>
	  <b-form-group>
        <b-form-input v-model="option.lineHeight" />
      </b-form-group>

	  <label>显示内容</label>
	  <b-form-group>
        <b-form-input v-model="option.fields" />
      </b-form-group>


    </b-form>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { reactive, toRefs,computed } from "@vue/composition-api";
import store from "@/store";
import vSelect from "vue-select";

export default {
  name: "G2ProgressRingSettings",
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: ["item"],
  setup(props) {
    const { item } = props;
    const event = reactive({
		option:{
			percent:"0.6",
			radius:"0.98",
			innerRadius:'0.85',
			color: "success",
			fontSize:'12px',
			lineHeight: "14px",
			fields:"进度"
		},
		color_list: computed(() => {
        let list = [];
        Object.keys(store.state.appConfig.colors).map((color) => {
          list.push({
            text: color,
            value: color,
          });
        });
        return list;
      }),
	});
    // watch(() => event.selectedFile, (file) => {
    // 	if (!!file) {
    // 		uploadFile(file)
    // 	}
    // })
    //更新store的数据
    // const update_store_options=()=>{
    // 	let options = store.state['panel-group']['panel-modal']['options']
    // 	options.list = event.list
    // 	store.commit('panel-group/UPDATE_PANEL_MODAL_OPTIONS', { ...options })
    // }
    return {
      ...toRefs(event),
    };
  },
};
</script>
