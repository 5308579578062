/**
 * 系统管理相关路由
 */
export default [
	// *===============================================---*
	// *--------- 用户列表设置 -----------------------------------*
	// *===============================================---*
	{
		path: "/admin/users/list",
		name: "admin-users-list",
		component: () => import("@/views/admin/user/users-list/UsersList.vue"),
		params: {
			title: "User List",
			levels: [
				{
					title: "System Manage",
				},
			],
		},
	},
	{
		path: "/admin/users/edit/:id",
		name: "admin-users-edit",
		params: {
			title: "User Edit",
			levels: [
				{
					title: "System Manage",
				},
				{
					title: "User List",
					name: "admin-users-list",
				},
			],
		},
		props: true,
		component: () => import("@/views/admin/user/users-edit/UsersEdit.vue"),
	},
	{
		path: "/admin/users/new",
		name: "admin-users-new",
		params: {
			title: "User New",
			levels: [
				{
					title: "System Manage",
				},
				{
					title: "User List",
					name: "admin-users-list",
				},
			],
		},
		props: true,
		component: () => import("@/views/admin/user/users-edit/UsersEdit.vue"),
	},

	// *===============================================---*
	// *--------- 项目列表设置 -------------------------------*
	// *===============================================---*
	{
		path: "/admin/projects/list",
		name: "admin-projects-list",
		params: {
			title: "Project List",
			levels: [
				{
					title: "System Manage",
				},
			],
		},
		component: () => import("@/views/admin/project/projects-list/ProjectsList.vue"),
	},
	// {
	// 	path: '/admin/projects/view/:id',
	// 	name: 'admin-projects-view',
	// 	component: () => import('@/views/admin/project/projects-view/ProjectsView.vue'),
	// },
	{
		path: "/admin/projects/edit/:id",
		name: "admin-projects-edit",
		params: {
			id: null,
			title: "Project Edit",
			levels: [
				{
					title: "System Manage",
				},
				{
					title: "Project List",
					name: "admin-projects-list",
				},
			],
		},
		props: true,
		component: () => import("@/views/admin/project/projects-edit/ProjectsEdit.vue"),
	},
	// *===============================================---*
	// *--------- 个人账户设置 -------------------------------*
	// *===============================================---*
	{
		path: "/admin/account-setting",
		name: "admin-account-setting",
		params: {
			title: "Account Settings",
			levels: [],
		},
		component: () => import("@/views/admin/account-setting/AccountSetting.vue"),
	},
	// *===============================================---*
	// *--------- menus ----------------------------------*
	// *===============================================---*
	{
		path: "/admin/menus/list",
		name: "admin-menus-list",
		params: {
			title: "Menu List",
			levels: [
				{
					title: "System Manage",
				},
			],
		},
		component: () => import("@/views/admin/menu/menus-list/MenusList.vue"),
	},
];
