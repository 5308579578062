export default [
	{
		path: "/:project_id/construction/green",
		name: "construction-green",
		component: () => import("@/views/front/construction/green"),
	},
	{
		path: "/:project_id/construction/health",
		name: "construction-health",
		component: () => import("@/views/front/construction/health"),
	},
	{
		path: "/:project_id/construction/nearly-zero",
		name: "construction-nearly-zero",
		component: () => import("@/views/front/construction/nearly_zero"),
	},
	{
		path: "/:project_id/construction/zero-carbon",
		name: "construction-zero-carbon",
		component: () => import("@/views/front/construction/zero_carbon"),
	},
];
