import { showToast } from "@/libs/utils/showToast";
import useJwt from "@/libs/auth/jwt/useJwt";
import router from "@/router";

export function logout() {
	showToast(null, "提示", "退出登陆");
	setTimeout(() => {
		localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
		localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
		localStorage.removeItem("userData");
		router.push({ name: "auth-login" });
	}, 500);
}
