import Vue from "vue";

//自动遍历文件夹下所有文件，自动注册为vue全局组件（view为展示文件，settings为配置文件）
export default function auto_import_panel_components() {
	const requireComponent_view = require.context("./", true, /View.vue$/);
	// './'操作对象为当前目录
	requireComponent_view.keys().forEach((element) => {
		const config = requireComponent_view(element);
		const componentName = "panel-component-" + element.replace(/^\.\//, "").replace(/\/View\.\w+$/, "");
		Vue.component(componentName, config.default || config);
		// console.log('auto_import', componentName)
	});

	const requireComponent_settings = require.context("./", true, /Settings.vue$/);
	// './'操作对象为当前目录
	requireComponent_settings.keys().forEach((element) => {
		const config = requireComponent_settings(element);
		const componentName = "panel-component-" + element.replace(/^\.\//, "").replace(/\/Settings\.\w+$/, "") + "-settings";
		Vue.component(componentName, config.default || config);
		// console.log('auto_import', componentName)
	});
}
